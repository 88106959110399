body { background-color: #ffffff; }
body { color: var(--clr-6029); }
[data-pad="0"] { margin-top: 10px; }

@media #{$medium-up} {
[data-pad="0"] { margin-top: 20px; }

}
[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 60px;

@media #{$large-up} {
font-size: 110px;

}
}
h2 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 36px;

@media #{$medium-up} {
font-size: 40px;

}
@media #{$large-up} {
font-size: 45px;

}
}
h3 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Nunito Sans';
font-weight: 800;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
text-decoration: underline;
letter-spacing: 0;
font-size: 18px;

}
h6 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: italic;
line-height: 1.1;
letter-spacing: 0;
font-size: 45px;

@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 10px;

}
@media #{$large-up} {
font-size: 14px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
.me-Quote .quote-author {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.3;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 24px;

}
}
summary {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Nunito Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-6029);}
a:hover {color: var(--clr-6030);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-6029);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-6029);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-6029);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: #ffffff;
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-6031);
color: var(--clr-6029);
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-6031);
color: var(--clr-6029);
 }
.MES5 {
background-color: var(--clr-6031);
color: var(--clr-6029);
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: var(--clr-6029);
 }
 }
a.MEC5 { color: var(--clr-6029);
 }
cite.MEC5{
color: var(--clr-6029);
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-6032);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-6032);
color: #ffffff;
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-6032);
 }
.MES8 {
background-color: var(--clr-6032);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-6032);
color: #ffffff;
 }
.MES9 {
background-color: var(--clr-6032);
color: #ffffff;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
 }
cite.MEC9{
color: #ffffff;
}
/* Light:10 */
.MES10 {
background-color: var(--clr-6033);
color: #ffffff;
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-6033);
color: #ffffff;
 }
.MES11 {
background-color: var(--clr-6033);
color: #ffffff;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
cite.MEC11{
color: #ffffff;
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-6034);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-6034);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-6034);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 14.4px;
 }
.MES14 {
font-size: 14.4px;
 }
cite.MEC14{
font-size: 14.4px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-6037);}
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6031);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-6037);}
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6031);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-6029);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-6030);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-6038);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-6036);
font-size: 60px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 60px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: #ffffff;}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-6029);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-6037);
color: var(--clr-6029);
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-6033);
font-size: 14.4px;
 }
.MES22 {
background-color: var(--clr-6033);
font-size: 14.4px;
 }
cite.MEC22{
font-size: 14.4px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-6035);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-6035);
color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
cite.MEC24{
color: #ffffff;
}
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-6037) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-6029);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6033) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-6029);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6033) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-6031);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 110px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 45px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 36px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 50px; }; }
 }
a.MEC26 { color: var(--clr-6029);
&:hover { color: var(--clr-6031);}
 }
cite.MEC26{
color: var(--clr-6029);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Get Started:27 */
.MES27 {
background-color: #ffffff;
color: var(--clr-6029);
font-size: 16.94px;
@media #{$medium-up} {
font-size: 12.1px;
};
@media #{$large-up} {
font-size: 18.2px;
};
padding: 20px;

 }
/* Header panel:28 */
.MES28 {
color: #ffffff;
padding: 5px 15px;

@media #{$large-up} {
padding: 15px;

}
 }
.MES28 {
color: #ffffff;
padding: 5px 15px;

@media #{$large-up} {
padding: 15px;

}
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
a.MEC28 { color: #ffffff;
 }
cite.MEC28{
color: #ffffff;
}
/* Main Menu White:29 */
nav.me-Menu.MES29 {
& .menu-item.MEC29, & .menu-item.MEC29 > div.MEC29{ & > a.MEC29{color: #ffffff;
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC29 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC29 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC29 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC29 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC29:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC29:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC29{background-color: #ffffff; &:hover {background-color: 6;}
}
& .menu-item.MEC29, & .menu-item.MEC29 > div.MEC29{ & > a.MEC29{color: var(--clr-6029);
font-size: 14.4px;
}
 &:hover > a.MEC29{color: 2;
}
 }

}
}
 }
/* Black panel:30 */
.MES30 {
background-color: var(--clr-6035);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6086/2138');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px 20px 60px 20px;

@media #{$large-up} {
padding: 50px;

}
 }
.MES30 {
background-color: var(--clr-6035);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6086/2138');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px 20px 60px 20px;

@media #{$large-up} {
padding: 50px;

}
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
a.MEC30 { color: #ffffff;
 }
.MEC30 li {color: #ffffff;}
cite.MEC30{
color: #ffffff;
}
/* Aqua panel:31 */
.MES31 {
background-color: var(--clr-6029);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6077/2136');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
 }
.MES31 {
background-color: var(--clr-6029);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6077/2136');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
a.MEC31 { color: var(--clr-6029);
 }
.MEC31 li {color: #ffffff;}
cite.MEC31{
color: #ffffff;
}
/* Dark:32 */
.MES32 {
background-color: var(--clr-6035);
color: #ffffff;
 }
/* Dark:33 */
.MES33 {
background-color: var(--clr-6035);
color: #ffffff;
 }
.MES33 {
background-color: var(--clr-6035);
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
a.MEC33 { color: #ffffff;
 }
.MEC33 li {color: #ffffff;}
cite.MEC33{
color: #ffffff;
}
/* Shade 2:34 */
.MES34 {
background-color: var(--clr-6039);
color: #ffffff;
 }
/* Shade 2:35 */
.MES35 {
background-color: var(--clr-6039);
color: #ffffff;
 }
.MES35 {
background-color: var(--clr-6039);
color: #ffffff;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
 }
.MEC35 li {color: #ffffff;}
cite.MEC35{
color: #ffffff;
}
/* Shade 3:36 */
.MES36 {
background-color: var(--clr-6040);
color: var(--clr-6029);
&:hover { color: #ffffff;}
 }
/* Shade 3:37 */
.MES37 {
background-color: var(--clr-6040);
color: var(--clr-6029);
 }
.MES37 {
background-color: var(--clr-6040);
color: var(--clr-6029);
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: var(--clr-6029);
 }
 }
cite.MEC37{
color: var(--clr-6029);
}
/* Teal panel:38 */
.MES38 {
background-color: var(--clr-6033);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6047/2131');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
 }
.MES38 {
background-color: var(--clr-6033);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6047/2131');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
a.MEC38 { color: #ffffff;
&:hover { color: var(--clr-6052);}
 }
.MEC38 li {color: #ffffff;}
cite.MEC38{
color: #ffffff;
}
/* Musk panel:39 */
.MES39 {
background-color: var(--clr-6034);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6087/2135');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
 }
.MES39 {
background-color: var(--clr-6034);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6087/2135');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: #ffffff;
 }
 }
a.MEC39 { color: #ffffff;
 }
.MEC39 li {color: #ffffff;}
cite.MEC39{
color: #ffffff;
}
/* White panel:40 */
.MES40 {
background-color: #ffffff;
color: var(--clr-6029);
padding: 50px 20px;

@media #{$large-up} {
padding: 100px 50px 100px 0;

}
 }
.MES40 {
background-color: #ffffff;
color: var(--clr-6029);
padding: 50px 20px;

@media #{$large-up} {
padding: 100px 50px 100px 0;

}
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: var(--clr-6029);
 }
 }
a.MEC40 { color: var(--clr-6035);
 }
.MEC40 li {color: var(--clr-6029-flat);}
cite.MEC40{
color: var(--clr-6029);
}
/* Button Black:41 */
.MES41 {
background-color: var(--clr-6029);
color: #ffffff;
padding: 15px 30px;

 }
/* Smart Phone:42 */
.MES42 {
background-color: #ffffff;
border-radius: 25px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 40px 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES42 {
background-color: #ffffff;
border-radius: 25px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 40px 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* White border:43 */
.MES43 {
font-size: 20.7px;
@media #{$large-up} {
font-size: 21.6px;
};
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES43 {
font-size: 20.7px;
@media #{$large-up} {
font-size: 21.6px;
};
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
cite.MEC43{
font-size: 20.7px;
@media #{$large-up} {
font-size: 21.6px;
};
}
/* Footer Menu White:44 */
nav.me-Menu.MES44 {
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: #ffffff;
font-size: 12.6px;
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC44 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC44 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC44 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC44 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Footer Menu White:45 */
nav.me-Menu.MES45 {
& .menu-item.MEC45, & .menu-item.MEC45 > div.MEC45{ & > a.MEC45{color: #ffffff;
font-size: 12.6px;
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC45 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC45 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC45 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC45 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Coffee panel:46 */
.MES46 {
background-color: var(--clr-6031);
color: var(--clr-6029);
 }
.MES46 {
background-color: var(--clr-6031);
color: var(--clr-6029);
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: var(--clr-6029);
 }
 }
a.MEC46 { color: var(--clr-6035);
 }
.MEC46 li {color: var(--clr-6035-flat);}
cite.MEC46{
color: var(--clr-6029);
}
/* footer logo:47 */
.MES47 {
 }
.MES47 {
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: #ffffff;
 }
h1.MEC47 { font-size: 42px; }
h2.MEC47 { font-size: 25.2px; }
h3.MEC47 { font-size: 15.4px; }
h4.MEC47 { font-size: 12.6px; }
h5.MEC47 { font-size: 12.6px; }
h6.MEC47 { font-size: 31.5px; }
 }
/* Background image:48 */
.MES48 {
background-color: var(--clr-6040);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/78/45');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES48 {
background-color: var(--clr-6040);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/78/45');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
/* white transparent panel:50 */
.MES50 {
background-color: var(--clr-6042);
color: var(--clr-6029);
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES50 {
background-color: var(--clr-6042);
color: var(--clr-6029);
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;
 }
 }
a.MEC50 { color: var(--clr-6029);
 }
.MEC50 li {color: var(--clr-6029-flat);}
cite.MEC50{
color: var(--clr-6029);
}
/* White Transparent BG:51 */
.MES51 {
background-color: var(--clr-6036);
color: var(--clr-6029);
padding: 5px;

 }
.MES51 {
background-color: var(--clr-6036);
color: var(--clr-6029);
padding: 5px;

 }
cite.MEC51{
color: var(--clr-6029);
}
/* Homepage header:52 */
.MES52 {
color: #ffffff;
 }
.MES52 {
color: #ffffff;
h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: #ffffff;
 }
 }
a.MEC52 { color: #ffffff;
 }
cite.MEC52{
color: #ffffff;
}
/* Hover Overlay:53 */
.MES53 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-6036);}
border-width: 5px;
border-style: solid;
border-color: var(--clr-6031);
 }
.MES53 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-6036);}
border-width: 5px;
border-style: solid;
border-color: var(--clr-6031);
 }
a.MEC53 { color: var(--clr-6029);
&:hover { color: var(--clr-6034);}
 }
/* Accordion:54 */
details.MES54 {
& > summary{background-color: var(--clr-6043);
}
 }
/* Accordion Style:55 */
details.MES55 {
& > summary{background-color: var(--clr-6043);
}
& > summary{padding: 15px 20px;}

& > article{padding: 15px;}

& > article {border-width: 1px;
border-color: #ffffff;
} }
/* Accordion Style Dark:56 */
details.MES56 {
& > summary{background-color: var(--clr-6044);
}
& > summary{padding: 15px;}

& > article{padding: 15px 0;}

& > article {border-width: 1px;
border-color: #ffffff;
}& > summary { font-size:19.14px;
@media #{$medium-up} {
font-size:19.8px;
}
 }
 }
/* Link Colour:57 */
.MES57 {
color: #ffffff;
 }
.MES57 {
color: #ffffff;
 }
a.MEC57 { color: #ffffff;
&:hover { color: var(--clr-6029);}
 }
cite.MEC57{
color: #ffffff;
}
/* Link Industry:58 */
.MES58 {
 }
/* Plain text:59 */
.MES59 {
background-color: transparent;
color: var(--clr-6029);
&:hover { color: var(--clr-6035);}
 }
/* Blue Grey:60 */
.MES60 {
background-color: var(--clr-6041);
color: #ffffff;
 }
.MES60 {
background-color: var(--clr-6041);
color: #ffffff;
h1.MEC60, h2.MEC60, h3.MEC60, h4.MEC60, h5.MEC60, h6.MEC60 { color: #ffffff;
 }
 }
a.MEC60 { color: var(--clr-6045);
&:hover { color: var(--clr-6034);}
 }
.MEC60 li {color: #ffffff;}
cite.MEC60{
color: #ffffff;
}
/* white transparent panel2:61 */
.MES61 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES61 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC61, h2.MEC61, h3.MEC61, h4.MEC61, h5.MEC61, h6.MEC61 { color: #ffffff;
 }
 }
a.MEC61 { color: #ffffff;
 }
.MEC61 li {color: #ffffff;}
cite.MEC61{
color: #ffffff;
}
/* green panel:63 */
.MES63 {
background-color: var(--clr-6046);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6078/2137');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-6029);
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
 }
.MES63 {
background-color: var(--clr-6046);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/6078/2137');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-6029);
padding: 20px;

@media #{$large-up} {
padding: 50px;

}
h1.MEC63, h2.MEC63, h3.MEC63, h4.MEC63, h5.MEC63, h6.MEC63 { color: #ffffff;
 }
 }
a.MEC63 { color: var(--clr-6029);
 }
.MEC63 li {color: #ffffff;}
cite.MEC63{
color: var(--clr-6029);
}
/* white transparent panel2:65 */
.MES65 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES65 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC65, h2.MEC65, h3.MEC65, h4.MEC65, h5.MEC65, h6.MEC65 { color: var(--clr-6029);
 }
 }
a.MEC65 { color: var(--clr-6029);
 }
.MEC65 li {color: #ffffff;}
cite.MEC65{
color: #ffffff;
}
/* Iput field:66 */
.me-block > .field-wrap.MES66 { 
& label {  }
 }
/* Aqua:67 */
.MES67 {
background-color: var(--clr-6047);
color: #ffffff;
&:hover { color: var(--clr-6036);}
 }
/* Green:68 */
.MES68 {
background-color: var(--clr-6046);
color: #ffffff;
&:hover { color: var(--clr-6036);}
 }
/* BlueGrey:69 */
.MES69 {
background-color: var(--clr-6048);
color: #ffffff;
 }
.MES69 {
background-color: var(--clr-6048);
color: #ffffff;
h1.MEC69, h2.MEC69, h3.MEC69, h4.MEC69, h5.MEC69, h6.MEC69 { color: #ffffff;
 }
 }
a.MEC69 { color: #ffffff;
 }
.MEC69 li {color: #ffffff;}
cite.MEC69{
color: #ffffff;
}
/* Bluegrey:70 */
.MES70 {
background-color: var(--clr-6048);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Greenlight:71 */
.MES71 {
background-color: #7f9a81;
color: #ffffff;
 }
.MES71 {
background-color: #7f9a81;
color: #ffffff;
h1.MEC71, h2.MEC71, h3.MEC71, h4.MEC71, h5.MEC71, h6.MEC71 { color: #ffffff;
 }
 }
a.MEC71 { color: #ffffff;
 }
.MEC71 li {color: #ffffff;}
cite.MEC71{
color: #ffffff;
}
/* Greenlight:72 */
.MES72 {
background-color: #7f9a81;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 8px;
};
@media #{$large-up} {
font-size: 14px;
};
 }
/* Pinkmusk:73 */
.MES73 {
background-color: var(--clr-6049);
color: #ffffff;
 }
.MES73 {
background-color: var(--clr-6049);
color: #ffffff;
h1.MEC73, h2.MEC73, h3.MEC73, h4.MEC73, h5.MEC73, h6.MEC73 { color: #ffffff;
 }
 }
a.MEC73 { color: #ffffff;
 }
cite.MEC73{
color: #ffffff;
}
/* Pinkmusk:74 */
.MES74 {
background-color: var(--clr-6049);
 }
/* Peach:75 */
.MES75 {
background-color: var(--clr-6050);
color: #ffffff;
 }
.MES75 {
background-color: var(--clr-6050);
color: #ffffff;
h1.MEC75, h2.MEC75, h3.MEC75, h4.MEC75, h5.MEC75, h6.MEC75 { color: #ffffff;
 }
 }
a.MEC75 { color: #ffffff;
 }
cite.MEC75{
color: #ffffff;
}
/* Peach:76 */
.MES76 {
background-color: var(--clr-6050);
 }
/* White Background:78 */
.MES78 {
background-color: #ffffff;
color: var(--clr-6029);
 }
.MES78 {
background-color: #ffffff;
color: var(--clr-6029);
h1.MEC78, h2.MEC78, h3.MEC78, h4.MEC78, h5.MEC78, h6.MEC78 { color: var(--clr-6029);
 }
 }
a.MEC78 { color: var(--clr-6035);
&:hover { color: var(--clr-6033);}
 }
.MEC78 li {color: var(--clr-6029-flat);}
cite.MEC78{
color: var(--clr-6029);
}
/* White:79 */
.MES79 {
background-color: #ffffff;
color: var(--clr-6029);
padding: 20px;

@media #{$large-up} {
padding: 20px 30px;

}
 }
.MES79 {
background-color: #ffffff;
color: var(--clr-6029);
padding: 20px;

@media #{$large-up} {
padding: 20px 30px;

}
h1.MEC79, h2.MEC79, h3.MEC79, h4.MEC79, h5.MEC79, h6.MEC79 { color: var(--clr-6029);
 }
 }
a.MEC79 { color: var(--clr-6039);
 }
.MEC79 li {color: var(--clr-6035-flat);}
cite.MEC79{
color: var(--clr-6029);
}
/* Aqua:80 */
.MES80 {
background-color: var(--clr-6047);
color: #ffffff;
 }
.MES80 {
background-color: var(--clr-6047);
color: #ffffff;
h1.MEC80, h2.MEC80, h3.MEC80, h4.MEC80, h5.MEC80, h6.MEC80 { color: #ffffff;
 }
 }
a.MEC80 { color: #ffffff;
 }
cite.MEC80{
color: #ffffff;
}
/* Abstract BG:81 */
.MES81 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.46 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES81 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.46 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC81, h2.MEC81, h3.MEC81, h4.MEC81, h5.MEC81, h6.MEC81 { color: #ffffff;
 }
 }
a.MEC81 { color: #ffffff;
&:hover { color: var(--clr-6036);}
 }
cite.MEC81{
color: #ffffff;
}
/* Transparent Accordion:82 */
details.MES82 {
& > summary{padding: 20px 0;}

@media #{$large-up} {
& > summary{padding: 30px 0;}

}
& > article{padding: 5px 0;}

& > summary { font-size:19.8px;
@media #{$medium-up} {
font-size:22px;
}
 }
 }
/* News Panel larger text:83 */
.MES83 {
font-size: 19.8px;
@media #{$medium-up} {
font-size: 20.52px;
};
@media #{$large-up} {
font-size: 21.6px;
};
 }
.MES83 {
font-size: 19.8px;
@media #{$medium-up} {
font-size: 20.52px;
};
@media #{$large-up} {
font-size: 21.6px;
};
 }
.MEC83 li { 
font-size: 19.8px;

@media #{$medium-up} {
font-size: 20.52px;

}
@media #{$large-up} {
font-size: 21.6px;

}
 }
cite.MEC83{
font-size: 19.8px;
@media #{$medium-up} {
font-size: 20.52px;
};
@media #{$large-up} {
font-size: 21.6px;
};
}
/* Dots :84 */
.MES84 {
& .slick-dots {text-align: center;
 button {margin: 0 8px;
;border-color: 1;
background-color: 21;
background-clip: padding-box;
&:hover {background-color: 19;}
width:10px;
height:10px;
@media #{$medium-up} {
width:10px;
height:10px;
};
@media #{$large-up} {
width:10px;
height:10px;
};
&:hover{background-color: 19;}
}}
& .slick-dots{bottom:10px;
@media #{$large-up} {
bottom: 20px;};
} }
/* Panel Round Corners:85 */
.MES85 {
background-color: #ffffff;
color: var(--clr-6029);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES85 {
background-color: #ffffff;
color: var(--clr-6029);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC85, h2.MEC85, h3.MEC85, h4.MEC85, h5.MEC85, h6.MEC85 { color: var(--clr-6029);
 }
 }
cite.MEC85{
color: var(--clr-6029);
}
/* Package price panel:86 */
.MES86 {
font-size: 14.4px;
 }
.MES86 {
font-size: 14.4px;
h1.MEC86, h2.MEC86, h3.MEC86, h4.MEC86, h5.MEC86, h6.MEC86 { color: var(--clr-6039);
 }
 }
cite.MEC86{
font-size: 14.4px;
}
/* Round Icon:87 */
.MES87 {
background-color: var(--clr-6048);
color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 6px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES87 {
background-color: var(--clr-6048);
color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 6px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC87, h2.MEC87, h3.MEC87, h4.MEC87, h5.MEC87, h6.MEC87 { color: #ffffff;
 }
 }
cite.MEC87{
color: #ffffff;
}
/* Form Button:89 */
.MES89 {
background-color: var(--clr-6047);
&:hover {background-color: var(--clr-6051);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 12px;
};
@media #{$large-up} {
font-size: 17.5px;
};
padding: 20px 30px;

 }
/* Link Button:90 */
.MES90 {
background-color: var(--clr-6035);
&:hover {background-color: var(--clr-6048);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 12px;
};
@media #{$large-up} {
font-size: 17.5px;
};
padding: 20px 30px;

 }
/* Link Button2:91 */
.MES91 {
background-color: var(--clr-6045);
&:hover {background-color: var(--clr-6048);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 12px;
};
@media #{$large-up} {
font-size: 17.5px;
};
padding: 20px 30px;

 }
/* :92 */
.MES92 {
border-width: 5px;
border-style: solid;
border-color: var(--clr-6040);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES92 {
border-width: 5px;
border-style: solid;
border-color: var(--clr-6040);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
/* Upload file :93 */
.me-block > .field-wrap.MES93 { 
& label {  }
 }
/* Round Icon:94 */
.MES94 {
background-color: var(--clr-6048);
color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 6px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES94 {
background-color: var(--clr-6048);
color: #ffffff;
border-radius: 50px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 6px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC94, h2.MEC94, h3.MEC94, h4.MEC94, h5.MEC94, h6.MEC94 { color: #ffffff;
 }
 }
cite.MEC94{
color: #ffffff;
}
/* Coffee Colur:95 */
.MES95 {
background-color: var(--clr-6052);
color: var(--clr-6029);
 }
.MES95 {
background-color: var(--clr-6052);
color: var(--clr-6029);
h1.MEC95, h2.MEC95, h3.MEC95, h4.MEC95, h5.MEC95, h6.MEC95 { color: var(--clr-6029);
 }
 }
a.MEC95 { color: var(--clr-6029);
 }
cite.MEC95{
color: var(--clr-6029);
}
/* white transparent email white text:96 */
.MES96 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES96 {
background-color: var(--clr-6042);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC96, h2.MEC96, h3.MEC96, h4.MEC96, h5.MEC96, h6.MEC96 { color: #ffffff;
 }
 }
a.MEC96 { color: #ffffff;
 }
.MEC96 li {color: #ffffff;}
cite.MEC96{
color: #ffffff;
}
/* Link Button Green:97 */
.MES97 {
background-color: var(--clr-6047);
&:hover {background-color: var(--clr-6046);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 12px;
};
@media #{$large-up} {
font-size: 17.5px;
};
padding: 20px 30px;

 }
/* Popup window:98 */
.MES98 {
background-color: #ffffff;
color: var(--clr-6029);
border-width: 5px;
border-style: solid;
border-color: var(--clr-6040);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES98 {
background-color: #ffffff;
color: var(--clr-6029);
border-width: 5px;
border-style: solid;
border-color: var(--clr-6040);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC98, h2.MEC98, h3.MEC98, h4.MEC98, h5.MEC98, h6.MEC98 { color: var(--clr-6029);
 }
 }
cite.MEC98{
color: var(--clr-6029);
}
/* Border image:99 */
.MES99 {
border-radius: 6px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES99 {
border-radius: 6px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Scroll Header Panel:100 */
.MES100 {
background-color: var(--clr-6048);
 }
.MES100 {
background-color: var(--clr-6048);
 }
/* Olive Green Grey:101 */
.MES101 {
background-color: #74795c;
color: #ffffff;
 }
.MES101 {
background-color: #74795c;
color: #ffffff;
h1.MEC101, h2.MEC101, h3.MEC101, h4.MEC101, h5.MEC101, h6.MEC101 { color: #ffffff;
 }
 }
a.MEC101 { color: #ffffff;
&:hover { color: var(--clr-6037);}
 }
cite.MEC101{
color: #ffffff;
}
/* Email bg Colour:102 */
.MES102 {
background-color: #fafaf8;
 }
.MES102 {
background-color: #fafaf8;
 }
/* Email Popout bg colour:103 */
.MES103 {
background-color: #dbd1ca;
color: #ffffff;
 }
.MES103 {
background-color: #dbd1ca;
color: #ffffff;
h1.MEC103, h2.MEC103, h3.MEC103, h4.MEC103, h5.MEC103, h6.MEC103 { color: #ffffff;
 }
 }
a.MEC103 { color: #ffffff;
 }
cite.MEC103{
color: #ffffff;
}
/* Dusty Pink:104 */
.MES104 {
background-color: #dec2bb;
color: #ffffff;
 }
.MES104 {
background-color: #dec2bb;
color: #ffffff;
h1.MEC104, h2.MEC104, h3.MEC104, h4.MEC104, h5.MEC104, h6.MEC104 { color: #ffffff;
 }
 }
a.MEC104 { color: #ffffff;
 }
cite.MEC104{
color: #ffffff;
}
/* Site Frame:105 */
.MES105 {
background-color: #ffffff3e;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 15px 0 15px;

@media #{$large-up} {
padding: 20px 15px 0 15px;

}
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
 }
.MES105 {
background-color: #ffffff3e;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 15px 0 15px;

@media #{$large-up} {
padding: 20px 15px 0 15px;

}
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
h1.MEC105, h2.MEC105, h3.MEC105, h4.MEC105, h5.MEC105, h6.MEC105 { color: var(--clr-6029);
 }
 }
a.MEC105 { color: var(--clr-6029);
 }
cite.MEC105{
color: var(--clr-6029);
}
/* Site Frame border:106 */
.MES106 {
background-color: #1b1b1b2f;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
 }
.MES106 {
background-color: #1b1b1b2f;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
h1.MEC106, h2.MEC106, h3.MEC106, h4.MEC106, h5.MEC106, h6.MEC106 { color: var(--clr-6029);
 }
 }
a.MEC106 { color: var(--clr-6029);
 }
cite.MEC106{
color: var(--clr-6029);
}
/* Site Frame Inner border:107 */
.MES107 {
color: var(--clr-6029);
border-radius: 6px 6px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px 1px 0 1px;
border-style: solid;
border-color: transparent;
 }
.MES107 {
color: var(--clr-6029);
border-radius: 6px 6px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px 1px 0 1px;
border-style: solid;
border-color: transparent;
h1.MEC107, h2.MEC107, h3.MEC107, h4.MEC107, h5.MEC107, h6.MEC107 { color: var(--clr-6029);
 }
 }
a.MEC107 { color: var(--clr-6029);
 }
cite.MEC107{
color: var(--clr-6029);
}
/* Responsive Menu:108 */
nav.me-Menu.MES108 {
& .menu-item.MEC108, & .menu-item.MEC108 > div.MEC108{ & > a.MEC108{color: #ffffff;
font-size: 36px;
@media #{$medium-up} {
font-size: 32.4px;
}@media #{$large-up} {
font-size: 36px;
}}
 &:hover > a.MEC108{color: #dcdcdc;
}
 }
&.horizontal > .menu-item.MEC108 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC108 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC108 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC108 .sub-menu { border:1px;
 }
&.horizontal .menu-item.MEC108:hover { & > .sub-menu {transform: none !important;
  text-align: left;
 left: 0;
}}
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Mobile Frame:109 */
.MES109 {
background-color: #ffffff3e;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 10px 0 10px;

@media #{$large-up} {
padding: 15px 10px 0 10px;

}
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
 }
.MES109 {
background-color: #ffffff3e;
color: var(--clr-6029);
border-radius: 20px 20px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 10px 0 10px;

@media #{$large-up} {
padding: 15px 10px 0 10px;

}
border-width: 2px 2px 0 2px;
border-style: solid;
border-color: var(--clr-6036);
h1.MEC109, h2.MEC109, h3.MEC109, h4.MEC109, h5.MEC109, h6.MEC109 { color: var(--clr-6029);
 }
 }
a.MEC109 { color: var(--clr-6029);
 }
cite.MEC109{
color: var(--clr-6029);
}
/* Mobile Frame Inner border:110 */
.MES110 {
color: var(--clr-6029);
border-radius: 10px 10px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px 1px 0 1px;
border-style: solid;
border-color: transparent;
 }
.MES110 {
color: var(--clr-6029);
border-radius: 10px 10px 0 0;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px 1px 0 1px;
border-style: solid;
border-color: transparent;
h1.MEC110, h2.MEC110, h3.MEC110, h4.MEC110, h5.MEC110, h6.MEC110 { color: var(--clr-6029);
 }
 }
a.MEC110 { color: var(--clr-6029);
 }
cite.MEC110{
color: var(--clr-6029);
}
/* Panel BG left:111 */
.MES111 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES111 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC111, h2.MEC111, h3.MEC111, h4.MEC111, h5.MEC111, h6.MEC111 { color: #ffffff;
 }
 }
a.MEC111 { color: #ffffff;
&:hover { color: var(--clr-6036);}
 }
cite.MEC111{
color: #ffffff;
}
/* Tranqiul Grey:112 */
.MES112 {
background-color: var(--clr-7157);
color: #ffffff;
 }
.MES112 {
background-color: var(--clr-7157);
color: #ffffff;
h1.MEC112, h2.MEC112, h3.MEC112, h4.MEC112, h5.MEC112, h6.MEC112 { color: #ffffff;
 }
 }
a.MEC112 { color: #ffffff;
 }
cite.MEC112{
color: #ffffff;
}
/* Coffee Colur:113 */
.MES113 {
background-color: #cecdc6;
color: #ffffff;
 }
.MES113 {
background-color: #cecdc6;
color: #ffffff;
h1.MEC113, h2.MEC113, h3.MEC113, h4.MEC113, h5.MEC113, h6.MEC113 { color: #ffffff;
 }
 }
a.MEC113 { color: #ffffff;
 }
cite.MEC113{
color: #ffffff;
}
/* Link Button Cofee:114 */
.MES114 {
background-color: #cecdc6;
&:hover {background-color: var(--clr-6052);}
color: #ffffff;
&:hover { color: var(--clr-6029);}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 12px;
};
@media #{$large-up} {
font-size: 17.5px;
};
padding: 20px 30px;

@media #{$medium-up} {
padding: 20px 10px;

}
@media #{$large-up} {
padding: 20px 30px;

}
 }
/* Homepage Main Image BG:115 */
.MES115 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES115 {
background-color: var(--clr-6041);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/19142/7097');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC115, h2.MEC115, h3.MEC115, h4.MEC115, h5.MEC115, h6.MEC115 { color: #ffffff;
 }
 }
a.MEC115 { color: #ffffff;
&:hover { color: var(--clr-6036);}
 }
cite.MEC115{
color: #ffffff;
}
/* Testimonial Panel Grid:117 */
.MES117 {
background-color: #ffffff;
color: #000000;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES117 {
background-color: #ffffff;
color: #000000;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC117, h2.MEC117, h3.MEC117, h4.MEC117, h5.MEC117, h6.MEC117 { color: #000000;
 }
 }
cite.MEC117{
color: #000000;
}
/* Pill shape:118 */
.MES118 {
background-color: #ffffff;
color: var(--clr-6029);
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

 }
.MES118 {
background-color: #ffffff;
color: var(--clr-6029);
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

h1.MEC118, h2.MEC118, h3.MEC118, h4.MEC118, h5.MEC118, h6.MEC118 { color: var(--clr-6029);
 }
 }
cite.MEC118{
color: var(--clr-6029);
}
